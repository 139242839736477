<template>
  <div class="waxon_tm_testimonials" id="testimonial">
    <div class="container">
      <div class="waxon_tm_main_title">
        <div class="title" v-scroll-reveal="{ delay: 20, distance: '20px' }">
          <h3>What Clients Say<span class="bg">Testimonial</span></h3>
        </div>
      </div>
      <!-- waxon_tm-main_title -->

      <div class="testi_inner">
        <div class="left" v-scroll-reveal="{ delay: 40 }">
          <div class="quote_list">
            <ul>
              <li v-if="activetab === 1" class="tabcontent active">
                <img
                  class="svg"
                  src="../../assets/img/svg/quote-1.svg"
                  alt=""
                />
                <p class="text">
                  It's been a pleasure working together with Andrey on multiple
                  projects during the years. He's an exceptional storyteller and
                  has an experienced trainer, who knows how to engage and impact
                  any group
                </p>
                <div class="details">
                  <div class="image">
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + imgOne + ')' }"
                    ></div>
                  </div>
                  <div class="short">
                    <h3 class="author"><span>Stoyan Yankov</span></h3>
                    <h3 class="job">
                      <span
                        >Internationational keynote speaker &amp; author</span
                      >
                    </h3>
                  </div>
                </div>
              </li>
              <!-- End li -->

              <li v-if="activetab === 2" class="tabcontent active">
                <img
                  class="svg"
                  src="../../assets/img/svg/quote-1.svg"
                  alt=""
                />
                <p class="text">
                  Andrey is a very inspirational lecturer. He has the ability to
                  present scientificly routed insights in a memorable, easy to
                  understand and emotionally rich context. At the same time his
                  openness and curiosity during the sessions are spreading to
                  the audience, inviting great discussions and insightful
                  converstaions. I truly admire the way he and his team foster
                  an open, positive and collaborative network that grows
                  exponentially over time into a community with shared goals and
                  mission - to nurture the future leaders of Bulgaria and beyond
                </p>
                <div class="details">
                  <div class="image">
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + imgTwo + ')' }"
                    ></div>
                  </div>
                  <div class="short">
                    <h3 class="author"><span>Lilly Yordanova</span></h3>
                    <h3 class="job"><span>Operations Director</span></h3>
                  </div>
                </div>
              </li>
              <!-- End li -->

              <li v-if="activetab === 3" class="tabcontent active">
                <img
                  class="svg"
                  src="../../assets/img/svg/quote-1.svg"
                  alt=""
                />
                <p class="text">
                  Andrey is an amazing storyteller and trainer. He has a unique
                  way of integrating humour and bringing you into the workshops
                  which transforms some of the serious topics that he covers
                  from dry know-how into an interactive and memorable experience
                </p>
                <div class="details">
                  <div class="image">
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + imgThree + ')' }"
                    ></div>
                  </div>
                  <div class="short">
                    <h3 class="author"><span>Veselin Tonov</span></h3>
                    <h3 class="job">
                      <span>Founder and CEO, Epic Media Group</span>
                    </h3>
                  </div>
                </div>
              </li>
              <!-- End li -->
            </ul>
          </div>
        </div>
        <!-- End left -->

        <div class="right" v-scroll-reveal="{ delay: 80 }">
          <div class="image_list">
            <ul class="masonry">
              <li
                class="masonry_item"
                v-on:click="activetab = 1"
                :class="[activetab === 1 ? 'active' : '']"
              >
                <div class="image">
                  <img src="../../assets/img/thumbs/3-4.jpg" alt="thumb" />
                  <div
                    class="main"
                    :style="{ backgroundImage: 'url(' + imgOne + ')' }"
                  ></div>
                </div>
              </li>
              <!-- End image One -->
              <li
                class="masonry_item"
                v-on:click="activetab = 2"
                :class="[activetab === 2 ? 'active' : '']"
              >
                <div class="image">
                  <img src="../../assets/img/thumbs/4-3.jpg" alt="thumb" />
                  <div
                    class="main"
                    :style="{ backgroundImage: 'url(' + imgTwo + ')' }"
                  ></div>
                </div>
              </li>
              <!-- End image Two -->
              <li
                class="masonry_item"
                v-on:click="activetab = 3"
                :class="[activetab === 3 ? 'active' : '']"
              >
                <div class="image">
                  <img src="../../assets/img/thumbs/1-1.jpg" alt="thumb" />
                  <div
                    class="main"
                    :style="{ backgroundImage: 'url(' + imgThree + ')' }"
                  ></div>
                </div>
              </li>
              <!-- End image Three -->
            </ul>
          </div>
        </div>
        <!-- End right -->
      </div>
      <!-- End testi_inner -->
    </div>
  </div>
  <!-- End Testimonials -->
</template>

<script>
export default {
  data() {
    return {
      activetab: 1,
      imgOne: require("../../assets/img/about/1.jpg"),
      imgTwo: require("../../assets/img/about/2.jpg"),
      imgThree: require("../../assets/img/about/3.jpg"),
    };
  },
};
</script>

<style lang="scss" scoped></style>
