<template>
  <div class="news_inner">
    <ul>
      <li>
        <div class="list_inner" v-scroll-reveal="{ delay: 20 }">
          <div class="image">
            <img src="../../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              @click="showModal"
              :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
            ></div>
          </div>
          <!-- End image -->

          <div class="details">
            <div class="extra">
              <p class="date">By <a href="#">Andrey Iliev</a></p>
            </div>
            <h3 class="title" @click="showModal">Mental Health</h3>
          </div>
          <!-- End details -->
        </div>

        <!-- Start Modalbox -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive }"
            id="modal"
            @click="closeModal"
            v-if="isVisible"
          >
            <div class="modal-dialog modal-dialog-centered" @click.stop>
              <div class="modal-content">
                <div class="close" @click="closeModal">
                  <img
                    class="svg"
                    src="../../assets/img/svg/cancel.svg"
                    alt="cancel-img"
                  />
                </div>
                <!-- End .close -->

                <div class="waxon_tm_modalbox_news">
                  <div class="image">
                    <img src="../../assets/img/thumbs/4-3.jpg" alt="thumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
                    ></div>
                  </div>
                  <!-- End image -->
                  <div class="details">
                    <div class="extra">
                      <p class="date">By <a href="#">Andrey Iliev</a></p>
                    </div>
                    <h3 class="title">Mental Health</h3>
                  </div>
                  <!-- End details -->

                  <div class="descriptions">
                    <p class="bigger">
                      <b>Agenda</b> <br />
                      It consists of theoretical and practical parts. Theory -
                      illustrates the different parts of the brain from an
                      evolutionary point of view and how they influence our
                      behavior. Practice - various tools, exercises, and
                      activities are presented that aim to provide ways for
                      creating mental hygiene in the everyday life and the
                      workplace
                    </p>
                    <p>
                      <b>Results</b> <br /><br />
                      Achieving a greater sense of calm or inner peace and as a
                      result - reducing the level of anxiety
                      <br /><br />Increasing self-esteem, work productivity, and
                      efficiency as well as achieving significant improvements
                      in communication with colleagues <br /><br />Creating an
                      individual Mental Health Hygiene Program for each team
                      member with daily practices and rituals
                    </p>
                  </div>
                  <!-- End description -->

                  <!-- End news share -->
                </div>
                <!-- waxon_tm_modalbox_news -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End Modalbox -->
      </li>
      <!-- End li (Single News Block) -->

      <li>
        <div class="list_inner" v-scroll-reveal="{ delay: 40 }">
          <div class="image">
            <img src="../../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              @click="showModal2"
              :style="{ backgroundImage: 'url(' + imgSrc2 + ')' }"
            ></div>
          </div>
          <!-- End image -->

          <div class="details">
            <div class="extra">
              <p class="date">By <a href="#">Andrey Iliev</a></p>
            </div>
            <h3 class="title" @click="showModal2">Anatomy of the Sleep</h3>
          </div>
          <!-- End details -->
        </div>

        <!-- Start Modalbox -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive2 }"
            id="modal"
            @click="closeModal2"
            v-if="isVisible2"
          >
            <div class="modal-dialog modal-dialog-centered" @click.stop>
              <div class="modal-content">
                <div class="close" @click="closeModal2">
                  <img
                    class="svg"
                    src="../../assets/img/svg/cancel.svg"
                    alt="cancel-img"
                  />
                </div>
                <!-- End .close -->
                <div class="waxon_tm_modalbox_news">
                  <div class="image">
                    <img src="../../assets/img/thumbs/4-3.jpg" alt="thumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + imgSrc2 + ')' }"
                    ></div>
                  </div>
                  <!-- End image -->
                  <div class="details">
                    <div class="extra">
                      <p class="date">By <a href="#">Andrey Iliev</a></p>
                    </div>
                    <h3 class="title">Anatomy of Sleep</h3>
                  </div>
                  <!-- End details -->

                  <div class="descriptions">
                    <p class="bigger">
                      <b>Agenda</b> <br />
                      The workshop presents the principles underlying effective
                      sleep. It consists of theoretical and practical parts.
                      Theory - shares the latest pieces of research on sleep
                      with insights into the role of the environment, REM, and
                      NREM sleep cycles. Practice - focuses on principles for
                      creating an ideal sleeping environment and habits, and
                      rituals for improving significantly the quality of sleep
                      and as a result - the productivity at work
                    </p>
                    <p>
                      <b>Results</b> <br /><br />
                      Understanding the role of sleep on the well-being and
                      productivity of an individual <br /><br />Triggering a
                      conscious decision to prioritize sleep and pay more
                      attention to its quality <br /><br />Creating an
                      individual Sleep Hygiene Program for each team member with
                      daily practices and rituals
                    </p>
                  </div>
                  <!-- End description -->

                  <!-- End news share -->
                </div>
                <!-- waxon_tm_modalbox_news -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End Modalbox -->
      </li>
      <!-- End li (Single News Block) -->

      <li>
        <div class="list_inner" v-scroll-reveal="{ delay: 60 }">
          <div class="image">
            <img src="../../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              @click="showModal3"
              :style="{ backgroundImage: 'url(' + imgSrc3 + ')' }"
            ></div>
          </div>
          <!-- End image -->

          <div class="details">
            <div class="extra">
              <p class="date">By <a href="#">Andrey Iliev</a></p>
            </div>
            <h3 class="title" @click="showModal3">
              Memory, Attention &amp; Focus
            </h3>
          </div>
          <!-- End details -->
        </div>

        <!-- Start Modalbox -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive3 }"
            id="modal"
            @click="closeModal3"
            v-if="isVisible3"
          >
            <div class="modal-dialog modal-dialog-centered" @click.stop>
              <div class="modal-content">
                <div class="close" @click="closeModal3">
                  <img
                    class="svg"
                    src="../../assets/img/svg/cancel.svg"
                    alt="cancel-img"
                  />
                </div>
                <!-- End .close -->
                <div class="waxon_tm_modalbox_news">
                  <div class="image">
                    <img src="../../assets/img/thumbs/4-3.jpg" alt="thumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + imgSrc3 + ')' }"
                    ></div>
                  </div>
                  <!-- End image -->
                  <div class="details">
                    <div class="extra">
                      <p class="date">By <a href="#">By Andrey Iliev</a></p>
                    </div>
                    <h3 class="title">Memory, Attention &amp; Focus</h3>
                  </div>
                  <!-- End details -->

                  <div class="descriptions">
                    <p class="bigger">
                      <b>Agenda</b> <br />
                      It consists of theoretical and practical parts. Theory -
                      delves into the way memory works, what and how we
                      remember, and what hinders and affects remembering and
                      recalling any information. Practice - During the practical
                      part, a Memory System with techniques and tools is
                      presented that is easily applied in the workplace and
                      improves learning abilities and performance
                    </p>
                    <p>
                      <b>Results</b> <br /><br />
                      Learning memory techniques and tools organized in a Memory
                      System <br /><br />Structuring and organizing new
                      information using the Memory System helps people create
                      coherent and integrated mental representations of complex
                      concepts<br /><br />
                      Regular practice leads to acquiring the kind of deep
                      learning necessary to solve complex problems
                    </p>
                  </div>
                  <!-- End description -->

                  <!-- End news share -->
                </div>
                <!-- waxon_tm_modalbox_news -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End Modalbox -->
      </li>
      <!-- End li (Single News Block) -->
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      isActive2: false,
      isActive3: false,
      isVisible: false,
      isVisible2: false,
      isVisible3: false,
      imgSrc: require("../../assets/img/news/4.jpg"),
      imgSrc2: require("../../assets/img/news/5.jpg"),
      imgSrc3: require("../../assets/img/news/6.jpg"),
    };
  },
  methods: {
    showModal: function () {
      this.isActive = true;
      this.isVisible = true;
    },
    showModal2: function () {
      this.isActive2 = true;
      this.isVisible2 = true;
    },
    showModal3: function () {
      this.isActive3 = true;
      this.isVisible3 = true;
    },
    closeModal: function () {
      this.isActive = false;
      this.isVisible = false;
    },
    closeModal2: function () {
      this.isActive2 = false;
      this.isVisible2 = false;
    },
    closeModal3: function () {
      this.isActive3 = false;
      this.isVisible3 = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
