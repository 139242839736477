<template>
  <!-- SERVICES -->
  <div class="waxon_tm_service">
    <div class="container">
      <div class="service_inner">
        <ul>
          <hooper :settings="hooperSettings">
            <slide v-for="(service, i) in serviceContent" :key="i">
              <div class="list_inner">
                <img class="svg" :src="service.imgSrc" alt="service image" />
                <div class="details">
                  <h3>{{ service.title }}</h3>
                  <p>
                    {{ service.desc }}
                  </p>
                </div>
              </div>

              <!-- End item -->
            </slide>
          </hooper>
        </ul>
      </div>
    </div>
  </div>
  <!-- /SERVICES -->
</template>

<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
export default {
  components: {
    Hooper,
    Slide,
  },
  data() {
    return {
      serviceContent: [
        {
          imgSrc: require("../../assets/img/svg/code.svg"),
          title: "Talent Development",
          desc: ` The goal of talent development is to create a high-performance, sustainable organization that meets its strategic and operational goals and objectives.`,
        },
        {
          imgSrc: require("../../assets/img/svg/creativity.svg"),
          title: "Mental Health",
          desc: `Good mental health at work and good management go hand in hand. Workplaces with high levels of mental well-being are more productive.`,
        },
        {
          imgSrc: require("../../assets/img/svg/telegram.svg"),
          title: "Cognitive Psychology",
          desc: `Findings from cognitive psychology help us understand how people think, including how they acquire and store memories.`,
        },
      ],
      hooperSettings: {
        infiniteScroll: true,
        wheelControl: false,
        autoPlay: false,
        mouseDrag: true,
        transition: 800,
        playSpeed: 1500,
        breakpoints: {
          1200: {
            itemsToShow: 3,
          },
          576: {
            itemsToShow: 2,
          },
          280: {
            itemsToShow: 1,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss">
.service_inner {
  .hooper-slide {
    padding-right: 44px;
  }
}
</style>
