<template>
  <div class="waxon_tm_about" id="about">
    <div class="container">
      <div class="about_inner">
        <div class="left" v-scroll-reveal="{ delay: 40 }">
          <img
            class="thumbnail"
            src="../../assets/img/about/andrey.jpg"
            alt="man with sunglass"
          />
        </div>
        <!-- End left thumbnail -->
        <div class="right">
          <div class="name" v-scroll-reveal="{ delay: 40 }">
            <h3>Andrey Iliev<span class="bg">About</span></h3>
            <span class="job">Experience &amp; expertise</span>
          </div>
          <div class="text">
            <p v-scroll-reveal="{ delay: 60 }">
              My name is Andrey and I am a trainer with more than 10 years of
              experience organizing, moderating, and facilitating events and
              team buildings. The main domains I teach about are Cognitive
              Psychology, Mental Health, and Talent Development
            </p>
            <p v-scroll-reveal="{ delay: 80 }">
              My academic history includes an Executive MBA from AUBG and and an
              M.A. in Cognitive Semiotics from Aarhus University. I’m also the
              co-founder of Leader Academy and Samodiva Mastermind
            </p>
          </div>
          <div class="waxon_tm_button" v-scroll-reveal="{ delay: 100 }">
            <button class="ib-button" @click="showModal">
              <span>Learn More</span>
            </button>
          </div>
        </div>
        <!-- End right content -->
      </div>
    </div>

    <!-- Start Modalbox -->
    <transition name="fade">
      <!-- Modal -->
      <div
        :class="{ 'modal-mask': isActive }"
        id="modal"
        @click="closeModal"
        v-if="isVisible"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
          @click.stop
        >
          <div class="modal-content">
            <div class="waxon_tm_modalbox_about">
              <div class="close" @click="closeModal">
                <img
                  class="svg"
                  src="../../assets/img/svg/cancel.svg"
                  alt="cancel-img"
                />
              </div>
              <!-- End .close -->
              <div class="my_box">
                <div class="left">
                  <div class="about_title">
                    <h3>Language skills</h3>
                  </div>
                  <!-- End .abut_title -->
                  <div class="waxon_progress">
                    <div class="progress_inner">
                      <span
                        ><span class="label">Bulgarian</span
                        ><span class="number">98%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 95%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                    <div class="progress_inner">
                      <span
                        ><span class="label">English</span
                        ><span class="number">92%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 80%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                    <div class="progress_inner">
                      <span
                        ><span class="label">Spanish</span
                        ><span class="number">62%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 90%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                  </div>
                  <!-- End .waxon_progress -->
                </div>
                <!-- End .left -->
                <div class="right">
                  <div class="about_title">
                    <h3>Training Skills</h3>
                  </div>
                  <div class="waxon_progress">
                    <div class="progress_inner">
                      <span
                        ><span class="label">Storytelling</span
                        ><span class="number">91%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 95%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span
                        ><span class="label">Cognitive skills</span
                        ><span class="number">86%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 90%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span
                        ><span class="label">Memory</span
                        ><span class="number">88%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width: 85%"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End .right -->
              </div>
              <div class="counter">
                <div class="about_title">
                  <h3>Fun Facts</h3>
                </div>
                <ul>
                  <li>
                    <div class="list_inner">
                      <h3>100+</h3>
                      <span>Workshops</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>20+</h3>
                      <span>Bootcamps</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>6</h3>
                      <span>Universities</span>
                    </div>
                  </li>
                </ul>
                <!-- End ul -->
              </div>
              <!-- End .counter -->
              <div class="partners">
                <div class="about_title">
                  <h3>Noble Clients</h3>
                </div>
                <Brand />
              </div>
              <!-- End .partner -->
            </div>
            <!-- End .tokyo_tm_modalbox_about -->
          </div>
        </div>
      </div>
    </transition>
    <!-- End Modalbox -->
  </div>
  <!-- End about -->
</template>

<script>
import Brand from "../Brand.vue";
export default {
  components: {
    Brand,
  },
  data() {
    return {
      isActive: false,
      isVisible: false,
    };
  },
  methods: {
    showModal: function () {
      this.isActive = true;
      this.isVisible = true;
    },
    closeModal: function () {
      this.isActive = false;
      this.isVisible = false;
    },
  },
};
</script>
