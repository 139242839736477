<template>
  <div class="waxon_tm_copyright">
    <div class="container">
      <div class="copyright_inner">
        <ul>
          <li v-scroll-reveal="{ delay: 20, distance: '20px' }">
            <span>Elevate Design &copy; {{ new Date().getFullYear() }}</span>

            <span>
              <a
                href="https://themeforest.net/user/ib-themes"
                target="_blank"
                rel="noreferrer"
              >
              </a>
              All rights reserved</span
            >
          </li>
          <!-- End li -->

          <li v-scroll-reveal="{ delay: 40, distance: '20px' }">
            <span>,Sofia</span>
            <span>Bulgaria</span>
          </li>
          <!-- End li -->

          <li v-scroll-reveal="{ delay: 60, distance: '20px' }">
            <span><a href="#">hello@andreyiliev.com</a></span>
            <span>2125639 88 359+</span>
          </li>
          <!-- End li -->

          <li v-scroll-reveal="{ delay: 80, distance: '20px' }">
            <div class="social">
              <ul>
                <li v-for="(social, i) in socialList" :key="i">
                  <a :href="social.link" target="_blank">
                    <span class="first"
                      ><img class="svg" :src="social.icon" alt="social icon"
                    /></span>
                    <span class="second"
                      ><img class="svg" :src="social.icon" alt="social icon"
                    /></span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <!-- End .copyright_inner -->
    </div>
    <!-- End .container -->
  </div>
  <!-- End .waxon_tm_copyright -->
</template>

<script>
export default {
  data() {
    return {
      socialList: [
        {
          icon: require("../assets/img/svg/social/facebook.svg"),
          link: "https://www.facebook.com/andrey.iliev.77",
        },
        {
          icon: require("../assets/img/svg/social/linkedin.svg"),
          link: "https://www.linkedin.com/in/andrey-iliev-leader-academy-founder/",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
