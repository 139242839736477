<template>
  <div class="news_inner">
    <ul>
      <li>
        <div class="list_inner" v-scroll-reveal="{ delay: 20 }">
          <div class="image">
            <img src="../../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              @click="showModal"
              :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
            ></div>
          </div>
          <!-- End image -->

          <div class="details">
            <div class="extra">
              <p class="date">By <a href="#">Andrey Iliev</a></p>
            </div>
            <h3 class="title" @click="showModal">
              Team Strengths &amp; Talent Development
            </h3>
          </div>
          <!-- End details -->
        </div>

        <!-- Start Modalbox -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive }"
            id="modal"
            @click="closeModal"
            v-if="isVisible"
          >
            <div class="modal-dialog modal-dialog-centered" @click.stop>
              <div class="modal-content">
                <div class="close" @click="closeModal">
                  <img
                    class="svg"
                    src="../../assets/img/svg/cancel.svg"
                    alt="cancel-img"
                  />
                </div>
                <!-- End .close -->

                <div class="waxon_tm_modalbox_news">
                  <div class="image">
                    <img src="../../assets/img/thumbs/4-3.jpg" alt="thumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
                    ></div>
                  </div>
                  <!-- End image -->
                  <div class="details">
                    <div class="extra">
                      <p class="date">By <a href="#">Andrey Iliev</a></p>
                    </div>
                    <h3 class="title">
                      Team Strenghts &amp; Talent Development
                    </h3>
                  </div>
                  <!-- End details -->

                  <div class="descriptions">
                    <p class="bigger">
                      <b>Agenda</b> <br />
                      It consists of theoretical and practical parts. Theory -
                      defines talent identification and development theories,
                      the role of creating a nourishing work environment, and
                      ways to develop talents into strengths. Practice -
                      involves team members doing strengths sociometry where
                      each one discusses his/her top 5 strengths with his
                      teammates and how to best implement them into the workflow
                    </p>
                    <p>
                      <b>Results</b> <br /><br />
                      Understanding how the TOP 5 talent themes can be developed
                      at the workplace<br /><br />
                      Learning how to complement each other’s strengths and
                      weaknesses for achieving maximum results and outstanding
                      team performance <br /><br />Creating a TEAM Strengths
                      Analyses with an overview of the whole talent spectrum
                    </p>
                  </div>
                  <!-- End description -->

                  <!-- End news share -->
                </div>
                <!-- waxon_tm_modalbox_news -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End Modalbox -->
      </li>
      <!-- End li (Single News Block) -->

      <li>
        <div class="list_inner" v-scroll-reveal="{ delay: 40 }">
          <div class="image">
            <img src="../../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              @click="showModal2"
              :style="{ backgroundImage: 'url(' + imgSrc2 + ')' }"
            ></div>
          </div>
          <!-- End image -->

          <div class="details">
            <div class="extra">
              <p class="date">By <a href="#">Andrey Iliev</a></p>
            </div>
            <h3 class="title" @click="showModal2">
              Happiness at <br />the workplace
            </h3>
          </div>
          <!-- End details -->
        </div>

        <!-- Start Modalbox -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive2 }"
            id="modal"
            @click="closeModal2"
            v-if="isVisible2"
          >
            <div class="modal-dialog modal-dialog-centered" @click.stop>
              <div class="modal-content">
                <div class="close" @click="closeModal2">
                  <img
                    class="svg"
                    src="../../assets/img/svg/cancel.svg"
                    alt="cancel-img"
                  />
                </div>
                <!-- End .close -->
                <div class="waxon_tm_modalbox_news">
                  <div class="image">
                    <img src="../../assets/img/thumbs/4-3.jpg" alt="thumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + imgSrc2 + ')' }"
                    ></div>
                  </div>
                  <!-- End image -->
                  <div class="details">
                    <div class="extra">
                      <p class="date">By <a href="#">Andrey Iliev</a></p>
                    </div>
                    <h3 class="title">Happiness at the workplace</h3>
                  </div>
                  <!-- End details -->

                  <div class="descriptions">
                    <p class="bigger">
                      <b>Agenda</b> <br />
                      It consists of theoretical and practical parts. Theory -
                      delves into the 4 hormones of happiness - Endorphin,
                      Serotonin, Oxytocin, and Dopamine - the body’s chemical
                      messengers, known for their ability to help people bond,
                      feel joy, and experience pleasure. Practice - team members
                      are able to participate in activities that trigger the
                      natural production of the hormones of Happiness
                    </p>
                    <p>
                      <b>Results</b> <br /><br />
                      Getting to know each other while being involved in fun and
                      joyful activities <br /><br />Understanding the importance
                      of these happy chemicals makes team members take active
                      steps to improve their levels naturally
                      <br /><br />Practicing these activities regularly leads to
                      better mental health, mood, and increased efficiency in
                      the workplace
                    </p>
                  </div>
                  <!-- End description -->

                  <!-- End news share -->
                </div>
                <!-- waxon_tm_modalbox_news -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End Modalbox -->
      </li>
      <!-- End li (Single News Block) -->

      <li>
        <div class="list_inner" v-scroll-reveal="{ delay: 60 }">
          <div class="image">
            <img src="../../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              @click="showModal3"
              :style="{ backgroundImage: 'url(' + imgSrc3 + ')' }"
            ></div>
          </div>
          <!-- End image -->

          <div class="details">
            <div class="extra">
              <p class="date">By <a href="#">Andrey Iliev</a></p>
            </div>
            <h3 class="title" @click="showModal3">
              The Art <br />of Storytelling
            </h3>
          </div>
          <!-- End details -->
        </div>

        <!-- Start Modalbox -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive3 }"
            id="modal"
            @click="closeModal3"
            v-if="isVisible3"
          >
            <div class="modal-dialog modal-dialog-centered" @click.stop>
              <div class="modal-content">
                <div class="close" @click="closeModal3">
                  <img
                    class="svg"
                    src="../../assets/img/svg/cancel.svg"
                    alt="cancel-img"
                  />
                </div>
                <!-- End .close -->
                <div class="waxon_tm_modalbox_news">
                  <div class="image">
                    <img src="../../assets/img/thumbs/4-3.jpg" alt="thumb" />
                    <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + imgSrc3 + ')' }"
                    ></div>
                  </div>
                  <!-- End image -->
                  <div class="details">
                    <div class="extra">
                      <p class="date">By <a href="#">By Andrey Iliev</a></p>
                    </div>
                    <h3 class="title">The Art of Storytelling</h3>
                  </div>
                  <!-- End details -->

                  <div class="descriptions">
                    <p class="bigger">
                      <b>Agenda</b> <br />
                      The workshop defines the importance of storytelling in a
                      business context and the main elements which make a story
                      memorable. It delves into the right messages that
                      influence and stick. Except for the business benefits of
                      implementing storytelling, each participant understands
                      the mechanics of language for making his own empowering
                      story
                    </p>
                    <p>
                      <b>Results</b> <br /><br />
                      Team members create together storytelling around the
                      mission and values of the company <br /><br />Each team
                      member creates a personal empowering story that describes
                      and defines his desired personality <br /><br />Team
                      members use their imagination and creativity, cooperate
                      together and use stories for solving problems
                    </p>
                  </div>
                  <!-- End description -->

                  <!-- End news share -->
                </div>
                <!-- waxon_tm_modalbox_news -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End Modalbox -->
      </li>
      <!-- End li (Single News Block) -->
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      isActive2: false,
      isActive3: false,
      isVisible: false,
      isVisible2: false,
      isVisible3: false,
      imgSrc: require("../../assets/img/news/1.jpg"),
      imgSrc2: require("../../assets/img/news/2.jpg"),
      imgSrc3: require("../../assets/img/news/3.jpg"),
    };
  },
  methods: {
    showModal: function () {
      this.isActive = true;
      this.isVisible = true;
    },
    showModal2: function () {
      this.isActive2 = true;
      this.isVisible2 = true;
    },
    showModal3: function () {
      this.isActive3 = true;
      this.isVisible3 = true;
    },
    closeModal: function () {
      this.isActive = false;
      this.isVisible = false;
    },
    closeModal2: function () {
      this.isActive2 = false;
      this.isVisible2 = false;
    },
    closeModal3: function () {
      this.isActive3 = false;
      this.isVisible3 = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
